export const addAPaymentMethod = {
  order: 4,
  name: "Add a payment method",
  keywords: "manage remove credit card stripe",
  subcategory: "Manage your account",
  markdown: `# Add a payment method

  Tower Hunt's {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go pricing{% /inlineRouterLink %} looks first to your {% inlineRouterLink %}credit balance{% /inlineRouterLink %} and then to an authorized payment method. Read on to learn how to connect a credit card.

  {% callout type="check" marginStyles="mb-4" %}
  Tower Hunt partners with [Stripe](https://stripe.com) to manage payments and payouts.
  {% /callout %}

  ## Add initial payment method

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Spending on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Add a payment method**, enter your credit card information and click **Submit**.
  {% /tablessContainer %}

  ## Manage payment methods

  Once you have added a payment method, additional management options are handled via [Stripe's](https://stripe.com) dashboard.

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Spending on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Your payment methods**, click **Manage**.
  5. Add, edit, and remove payment information via [Stripe's](https://stripe.com) dashboard, which opens in a new tab.
  {% /tablessContainer %}`,
};
